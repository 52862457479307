// Main
$background-color: #fff;
$color: #3C3C3C;
$second-color: #878787;
$font-size: 14px;
$line-height: 145%;
$font-family: 'Inter', sans-serif;

$paragraph-margin-bottom: 15px;
$headers-margin-bottom: 15px;

$content-width: 1170px;

$map-height: calc(100vh - 176px);
$map-min-height: 600px;

$additional-color: #bbb;
$link-color: #0585F5;

$modal-background-color: #ECF4FF;
$modal-color: #414042;

$footer-background-color: #dce9ff;


// buttons
$button-border-radius: 30px;
$button-padding: 9px 30px;
$button-font-size: 14px;
$button-line-height: 18px;
$button-large-border-radius: 30px;
$button-large-padding: 9px 30px;
$button-large-font-size: 16px;
$button-large-line-height: 22px;
$button-mobile-padding: 9px 15px;
// buttons primary
$primary-button-color: #fff;
$primary-button-background: #0585F5;
$primary-button-border-color: #0585F5;
// buttons primary active
$primary-button-active-color: #fff;
$primary-button-active-background: #0469C2;
$primary-button-active-border-color: #0469C2;
// buttons primary disabled
$primary-button-disabled-color: #fff;
$primary-button-disabled-background: #BBBBBB;
$primary-button-disabled-border-color: #BBBBBB;
// buttons secondary
$secondary-button-color: #0585F5;
$secondary-button-background: #fff;
$secondary-button-border-color: #0585F5;
// buttons secondary active
$secondary-button-active-color: #fff;
$secondary-button-active-background: #0469C2;
$secondary-button-active-border-color: #0469C2;
// buttons secondary disabled
$secondary-button-disabled-color: #fff;
$secondary-button-disabled-background: #BBBBBB;
$secondary-button-disabled-border-color: #BBBBBB;
// buttons success
$success-button-color: #fff;
$success-button-background: #28a745;
$success-button-border-color: #28a745;
// buttons danger
$danger-button-color: #fff;
$danger-button-background: #dc3545;
$danger-button-border-color: #dc3545;

// Form Input
$input-font-size: 14px;
$input-line-height: 28px;
$input-color: #878787;
$input-bg-color: #fff;

// Left block
$left-block-padding-lg: 98px;
$left-block-padding-md: 50px;
$left-block-padding-sm: 25px;


