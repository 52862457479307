@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(180deg);
  }
}

@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(180deg);
  }
}

@-ms-keyframes rotate {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(180deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.spinner {
  position: relative;
  min-height: 100px;

  &:before,
  &:after {
    display: block;
    position: absolute;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99;
    left: 0;
    top: 0;
  }

  &:after {
    content: "\f2f1";
    font-family: "FontAwesome";
    font-size: 20px;
    line-height: 20px;
    color: white;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    -webkit-animation: rotate 2s linear infinite;
    -moz-animation: rotate 2s linear infinite;
    -o-animation: rotate 2s linear infinite;
    -ms-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    z-index: 999;
  }
}
